/* -------------------------- */
.Logout #logoutDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-right: solid 1px #FFC439;
    border-left: solid 1px #FFC439;
    border-bottom: solid 15px #FFC439;
    /* border-right: solid 2px rgb(108, 108, 108);
    border-left: solid 2px rgb(108, 108, 108);
    border-bottom: solid 20px rgb(108, 108, 108); */
    border-radius: 30px 30px 10px 10px;
    padding: 10px 10px 6px 10px;
    width: 253px;
    height: 314px;
    margin-top: 32px;
    overflow: hidden;
}

.Logout #nameDiv{
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Logout h1 {
    font-size: 30px;
    margin:0;
    color: #FFC439;
}

/* Edit Name and Password -------------------------- */
.Logout .fa-pen-to-square {
    font-size: 21px;
    padding-top: 15px;
    cursor: pointer;
}

.Logout form {
    border: solid 1px #FFC439;
    border-radius: 15px;
    padding: 11px 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
}

.Logout .fa-floppy-disk {
    font-size: 18px;
}

@media (max-width: 272px) {
    .Logout #logoutDiv {
        width: 89vw;
    }
}