.TooltipKeyboardShortcuts {
    font-size: 15px;
}

.TooltipKeyboardShortcuts #container>div>div {
    display: flex;
}

.TooltipKeyboardShortcuts #container>div>div:not(:last-child) {
    border-bottom: 1px dashed whitesmoke;
}

.TooltipKeyboardShortcuts #container>div>div>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
}

/* --- */
.TooltipKeyboardShortcuts .header {
    font-weight: bold;
    font-style: italic;
}

.TooltipKeyboardShortcuts .gesture,
.TooltipKeyboardShortcuts .keys {
    width: 100px;
}

.TooltipKeyboardShortcuts .result {
    width: 150px;
    /* color: #5ae1ff; */
}

/* Guide ------------------------------------- */
.TooltipKeyboardShortcuts #guide {
    max-width: 300px;
    display: inline-block;
    padding: 7px 0;
    color: red;
}

.TooltipKeyboardShortcuts h3 {
    margin: 9px 0 4px 0;
}

.TooltipKeyboardShortcuts a {
    color: red;
    text-decoration: underline;
    /* text-decoration-color: rgb(165, 140, 140); */
}

/* Tooltip ------------------------------------- */
.TooltipKeyboardShortcuts #tipTooltip {
    background-color: #3b3b3b;
    border-radius: 10px;
}

/* icon ------------------------------------- */
.TooltipKeyboardShortcuts .fa-circle-exclamation {
    color: #6d6d6d;
    font-size: 20px;
}

/* Mobile ------------------------------------- */
@media (max-width: 417px) {
    .TooltipKeyboardShortcuts {
        font-size: 13px;
    }

    .TooltipKeyboardShortcuts #container>div>div {
        padding: 1px;
    }

    .TooltipKeyboardShortcuts .keys {
        display: none;
    }

    .TooltipKeyboardShortcuts .gesture,
    .TooltipKeyboardShortcuts .result {
        width: 90px;
    }

    .TooltipKeyboardShortcuts #guide {
        max-width: 270px;
    }

}