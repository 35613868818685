/* general ------------------------------------------------ */
.List {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.List #cardsDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2px;
    padding: 3px 0 3px 0;
}

.List #settings {
    width: 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(41, 41, 41);
    border-right: solid 1px #FFC439;
    border-left: solid 1px #FFC439;
    border-top: solid 1px #FFC439;
    border-radius: 5px 5px 0 0;
    padding: 4px;
    margin-top: 1px;
}

.List #words {
    overflow: auto;
    box-sizing: border-box;
    /* margin-top: 2px; */
}

.List #startDiv {
    border: 1px solid #FFC439;
    border-bottom: 0;
    border-radius: 5px;
    height: 10px;
    margin: auto;
}

.List #endDiv {
    border: 1px solid #FFC439;
    border-top: 0;
    border-radius: 5px;
    font-size: 13px;
    font-weight: bold;
    margin: auto;
    height: 10px;
}

/* display buttons ------------------------------------------------ */
.List .unClicked-button {
    font-size: 12px;
    cursor: pointer;
    color: #FFC439;
    padding: 4px 6px;
    margin-top: 1px;
    border: 1px solid #FFC439;
    transition: 0.3s;
}

.List .unClicked-button.fa-table {
    border-radius: 2px 0 0 2px;
    color: #FFC439;
}

.List .unClicked-button.fa-grip {
    border-radius: 0 2px 2px 0;
}

.List .clicked-button {
    font-size: 12px;
    cursor: pointer;
    padding: 4px 6px;
    margin-top: 1px;
    border: 1px solid #FFC439;
    background-color: #FFC439;
    color: black;
}

.List .clicked-button.fa-table {
    border-radius: 2px 0 0 2px;
}

.List .clicked-button.fa-grip {
    border-radius: 0 2px 2px 0;
}

/* Hide-Translation button ------------------------------------------------ */
.List .fa-eye,
.List .fa-eye-slash {
    font-size: 21px;
    cursor: pointer;
    color: #FFC439;
}

/* Scrollbar ------------------------------------------------ */
/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c6c6c6;
    border-radius: 10px;
    border: 2px solid #c6c6c6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: transparent
}

/* loading image and audio -------------------- */
.List .wordTd,
.List .sentenceTd,
.List .wordDiv,
.List .sentenceDiv {
    position: relative;
}

.List .loading {
    position: absolute;
    top: 0;
    left: 0;
}

.List .loading img {
    width: 30px;
    height: 30px;
}

/* ------------------------------------------------ */
@media (max-width: 895px) {
    .List #settings {
        width: 110px;
    }

    .List .fa-eye,
    .List .fa-eye-slash {
        padding-right: 4px;
    }
}

/* Table ============================================================================= */
.Table table {
    border-collapse: separate;
    border: solid 1px #b39143;
    border-radius: 10px;
    width: 99.6vw;
}

.Table td,
.Table th {
    border-left: solid 1px #b39143;
    border-top: solid 1px #b39143;
    border-radius: 10px;
}

.Table th {
    border-top: none;
    font-size: 13px;
}

.Table td:first-child,
.Table th:first-child {
    border-left: none;
}