.TooltipSelectedText {
    color: black;
    font-size: 14.4px;
}

.TooltipSelectedText div,
.TooltipSelectedText a {
    display: block;
    cursor: pointer;
    background-color: rgb(111, 255, 207);
    border: 2px solid rgb(2, 137, 92);
    border-radius: 10px;
    padding: 5px 10px;
    transition: 0.2s;
}

.TooltipSelectedText div:hover,
.TooltipSelectedText a:hover {
    background-color: rgb(178, 205, 196);
}

.TooltipSelectedText div {
    font-weight: bold;
    margin-bottom: 5px;
}

.TooltipSelectedText a {
    color: black;
    text-decoration: none;
}
