.Status .active,
.Status .notActive {
    font-size: 30px;
    font-weight: 600;
}

/* colors ----------------- */
.Status .active,
/* .Status #trialActive, */
.Status .fa-heart {
    color: rgb(82, 255, 47);
}

.Status .notActive,
/* .Status #trialNotActive, */
.Status .fa-triangle-exclamation {
    color: red;
}

.Status span {
    color: #FFC439;
    font-weight: 600;
}

/* animation ----------------- */
.Status .blinker {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
