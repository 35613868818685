.PasswordReset #step3Div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.PasswordReset #step3Div>span{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}