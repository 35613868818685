/* Label ----------------- */
.Auth label {
    color: #f2f2f2;
    /* font-weight: 600; */
    font-size: 15px;
}

/* Input ----------------- */
.Auth input {
    color: #f2f2f2;
    background-color: rgb(21, 21, 21);
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    font-size: 15px;
    font-family: inherit;
    text-align: center;
    padding: 3px;
    margin: 1px 0 10px 0;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
}

.Auth input:focus {
    outline: none;
}

.Auth .errorMessage {
    color: rgb(255, 60, 60);
    font-size: 13px;
}

/* Buttons ----------------- */
.Auth button {
    color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    background-color: transparent;
    padding: 0;
    font-size: 15px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
}

@media (min-width: 426px) {
    .Auth button:hover {
        background-color: #f2f2f2;
        color: black;
    }
}

.Auth i {
    margin-left: 5px;
}