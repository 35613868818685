/* Test ------------------------------------- */
.Test {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    overflow-x: hidden;
}

/* Word and Sentence ------------------------------------- */
.Test #wordContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    height: calc(100% - 50px);
    box-sizing: border-box;
    padding: 5px;
    font-size: 4vh;
    letter-spacing: 1px;
    word-spacing: 1px;
    overflow-y: auto;
}

.Test #wordAndTranslationDiv {
    padding: 0px 8px;
    word-break: break-all;
    /* cursor: pointer; */
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.Test #sentenceAndTranslationDiv {
    padding: 0px 8px;
    overflow-y: auto;
    overflow-x: hidden;
    /* cursor: pointer; */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.Test #sentenceAndTranslationDiv>div {
    margin: auto;
}

.Test .translationDiv {
    color: rgb(143, 143, 143);
}

.Test .hideAnswer {
    color: rgb(21, 21, 21);
}

.Test .speechIcon {
    cursor: pointer;
    color: #5ae1ff;
    font-size: 22px;
    padding-left: 10px;
}

/* Img ------------------------------------- */
.Test #wordContainer #imgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vh;
    height: 50vh;
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
    cursor: pointer;
}

.Test #wordContainer #imgDiv img {
    max-width: 97%;
    max-height: 97%;
    border: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.Test #wordContainer #imgDiv .fa-arrows-up-down-left-right {
    font-size: 100px;
}

/* Controllers ------------------------------------- */
.Test #sumMutedScoreDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    background-color: #2e2e2e;
    border-radius: 10px;
    min-width: 250px;
}

.Test #sumMutedScoreDiv>div {
    padding: 0 12px;
}

/* .Test #sumMutedScoreDiv #sumDiv {
    width: 93px;
} */

.Test #sumMutedScoreDiv #scoreDiv {
    width: 10px;
}

/* .Test #sumMutedScoreDiv #updateDiv {
    width: 50px;
} */

/* .Test #sumMutedScoreDiv #MutedDiv {
    width: 88px;
} */

.Test #controllersDiv>#buttonsDiv {
    display: flex;
    gap: 10px;
    justify-content: center;
    font-size: 40px;
    margin-top: 3px;
    /* width: 300px; */
}

.Test #buttonsDiv>div {
    display: flex;
    gap: 10px;
}

.Test #scoreDiv {
    color: #FFC439;
    font-size: 20px;
    font-weight: bold;
}

.Test #MutedDiv {
    width: 12px;
}

.Test #hideAnswerDiv {
    width: 50px;
}

.Test i:hover {
    cursor: pointer;
}

/* colors */
.Test .fa-circle-arrow-left,
.Test .fa-circle-arrow-right {
    color: #f2f2f2;
}

.Test .fa-circle-minus {
    color: red;
}

.Test .fa-circle-plus {
    color: rgb(82, 255, 47);
}

.Test .muteIcon {
    color: #5ae1ff;
}

.Test .fa-eye,
.Test .fa-eye-slash {
    color: #FFC439;
}

/* Mobile ------------------------------------- */
@media (max-width: 400px) {
    .Test #wordContainer #imgDiv {
        width: 35vh;
        height: 25vh;
    }
}

@media (max-width: 266px) {
    .Test #buttonsDiv {
        font-size: 7vh;
    }
}

@media (max-width: 320px) {
    .Test #buttonsDiv {
        width: 95vw;
    }
}

/* Loading image and audio -------------------- */
.Test #wordAndTranslationDiv>div,
.Test #sentenceAndTranslationDiv>div>div {
    position: relative;
}

.Test #wordAndTranslationDiv .loading {
    position: absolute;
    top: 0;
    left: 0;
}

.Test #sentenceAndTranslationDiv .loading {
    position: absolute;
    top: 0;
    left: 0;
}

.Test .loading img {
    width: 40px;
    height: 40px;
    border: 0;
}

/* Update score -------------------- */
@keyframes flicker {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.Test .flicker-text {
    animation: flicker 1s infinite;
}

/* Display score -------------------- */
.Test #wordContainer {
    position: relative;
}

.Test #displayScoreDiv {
    position: absolute;
    top: 47%;
    left: 50;

    font-size: 40px;
    color: #FFC439;
    border-radius: 50px;
    padding: 0 18px;
}

.Test #displayScoreDiv>img {
    border: 0;
    width: 50px;
    height: 50px;
}

/* Tooltip -------------------- */
.Test #scoreTooltip,
.Test #updateTooltip,
.Test #muteTooltip,
.Test #previousTooltip,
.Test #minusScoreTooltip,
.Test #hideAnswerTooltip,
.Test #plusScoreTooltip,
.Test #nextTooltip {
    font-size: 15px;
    padding: 3px;
    color: black;
    font-weight: bold;
}

.Test #updateTooltip,
.Test #plusScoreTooltip {
    background-color: rgb(82, 255, 47);
}

.Test #scoreTooltip,
.Test #hideAnswerTooltip {
    background-color: #FFC439;
}

.Test #minusScoreTooltip {
    background-color: red;
}

.Test #previousTooltip,
.Test #nextTooltip {
    background-color: #f2f2f2;
}

.Test #muteTooltip {
    background-color: #5ae1ff;
}

/* --- */
.Test #displayScore_Tooltip {
    color: black;
    font-size: 20px;
    font-weight: bold;
    padding: 11px;
}

.Test .score1 {
    background-color: red;
}

.Test .score2 {
    background-color: #FFC439;
}

.Test .score3 {
    background-color: rgb(82, 255, 47);
}

/* selected-text-tooltip */
.Test #selected-text-tooltip {
    background-color: rgb(0, 255, 170);
    max-width: 270px;
    padding: 5px;
    border-radius: 10px;
}
