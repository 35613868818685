/* Media Player ------------------------------------- */
.MediaPlayer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.MediaPlayer #mediaPlayerDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* border: solid 1px #FFC439; */
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* Settings ------------------------------------- */
.MediaPlayer #settings-tooltip {
    background-color: #FFC439;
    padding: 6px;
}

.MediaPlayer #settings>form {
    background-color: rgb(41, 41, 41);
    padding: 5px 9px;
    font-size: 14px;
    font-weight: bold;
    color: #FFC439;
}

.MediaPlayer #settings>form:nth-child(2) {
    padding-bottom: 0;
}

.MediaPlayer #settings input {
    font-weight: bold;
    border: 2px solid #8d7743;
    border-radius: 5px;
    background-color: inherit;
    color: #FFC439;
    font-family: inherit;
    text-align: center;
    padding: 2px;
    margin: 0 1px;
    outline: none;
    width: 40px;
    font-size: 15px;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* close */
.MediaPlayer #settings .fa-circle-xmark {
    cursor: pointer;
    color: #FFC439;
    font-size: 21px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1px;
}

/* reset */
.MediaPlayer #resetSettings_div {
    background-color: rgb(41, 41, 41);
    padding-bottom: 8px;
}

.MediaPlayer #resetSettings_span {
    cursor: pointer;
    color: #FFC439;
    font-size: 13px;
    font-weight: bold;
    padding: 2.4px 6px;
    border: 2px solid #8d7743;
    border-radius: 5px;
    transition: 0.3s;
}

@media (min-width: 426px) {
    .MediaPlayer #resetSettings_span:hover {
        background-color: #FFC439;
        border: 2px solid #FFC439;
        color: black;
    }
}

/* Word and Sentence ------------------------------------- */
.MediaPlayer #wordContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
    height: calc(100% - 27px);
    box-sizing: border-box;
    padding: 5px;
    font-size: 4vh;
    letter-spacing: 1px;
    word-spacing: 1px;
    overflow-y: auto;
}

.MediaPlayer #wordAndTranslationDiv {
    padding: 0px 8px;
    word-break: break-all;
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.MediaPlayer #sentenceAndTranslationDiv {
    padding: 0 8px;
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.MediaPlayer #sentenceAndTranslationDiv>div {
    margin: auto;
}

.MediaPlayer .translationDiv {
    color: rgb(143, 143, 143);
}

.MediaPlayer .hideTranslation {
    color: rgb(21, 21, 21);
}

/* Img ------------------------------------- */
.MediaPlayer #wordContainer #imgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60vh;
    height: 50vh;
    border-bottom: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
    cursor: pointer;
}

.MediaPlayer #wordContainer #imgDiv img {
    max-width: 97%;
    max-height: 97%;
    border: 1px dashed rgb(97, 97, 97);
    border-radius: 10px;
}

.MediaPlayer #wordContainer #imgDiv .fa-arrows-up-down-left-right {
    font-size: 100px;
}

/* Controllers ------------------------------------- */
.MediaPlayer #controllersDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    padding-bottom: 3px;
}

.MediaPlayer i:hover {
    cursor: pointer;
}

/* play */
.MediaPlayer #playDiv {
    display: flex;
    gap: 14px;
}

/* Sum and Hide */
.MediaPlayer #sumDiv {
    margin: 0 6px;
    font-size: 16px;
    font-weight: 900;
    /* width: 45px; */
}

.MediaPlayer #hidingTranslationDiv {
    width: 39px;
}

.MediaPlayer #sumDiv,
.MediaPlayer .fa-eye,
.MediaPlayer .fa-eye-slash {
    color: #FFC439;
}

.MediaPlayer .fa-eye-slash,
.MediaPlayer .fa-eye {
    padding: 0 8px;
}

.MediaPlayer #updateDiv>span>.fa-pen-to-square {
    padding: 0 6px;
    font-size: 18px;
}

/* Mobile ------------------------------------- */
@media (max-width: 400px) {
    .MediaPlayer #wordContainer #imgDiv {
        width: 35vh;
        height: 25vh;
    }
}

/* loading image and audio -------------------- */
.MediaPlayer #wordAndTranslationDiv>div,
.MediaPlayer #sentenceAndTranslationDiv>div>div {
    position: relative;
}

.MediaPlayer #wordAndTranslationDiv .loading {
    position: absolute;
    top: 0;
    left: 0;
}

.MediaPlayer #sentenceAndTranslationDiv .loading {
    position: absolute;
    top: 0;
    left: 0;
}

.MediaPlayer .loading img {
    width: 40px;
    height: 40px;
    border: 0;
}

/* selected-text-tooltip */
.MediaPlayer #selected-text-tooltip {
    background-color: rgb(0, 255, 170);
    max-width: 270px;
    padding: 5px;
    border-radius: 10px;
    z-index: 100;
}

/* Display score -------------------- */
.MediaPlayer #wordContainer {
    position: relative;
}

.MediaPlayer #displayScoreDiv {
    position: absolute;
    top: 47%;
    left: 50;

    font-size: 40px;
    color: #FFC439;
    border-radius: 50px;
    padding: 0 18px;
}

.MediaPlayer #displayScoreDiv>img {
    border: 0;
    width: 50px;
    height: 50px;
}