.Navbar {
    position: fixed;
    /* top: 4px; */
    display: flex;
    width: 100%;
    justify-content: center;
}

.Navbar a {
    color: #f2f2f2;
    background-color: rgb(21, 21, 21);
    margin: 0 2px;
    border: 1px solid #444444;
    border-radius: 5px;
    transition: 0.3s;
    text-decoration: none;
    font-weight: bold;
    padding: 2.4px 20px;
    text-align: center;
}

.Navbar i {
    color: #FFC439;
}

@media (min-width: 426px) {
    .Navbar a:hover {
        background-color: #444444;
        border: 1px solid #f2f2f2;
    }
}

.Navbar .active {
    background-color: #444444;
    border: 1px solid #f2f2f2;
}

.Navbar .myActive {
    background-color: #444444;
    border: 1px solid #f2f2f2;
}

@media (max-width: 577px) {
    .Navbar a[href="/home"] {
        padding: 2.4px 14px;
    }

    .Navbar a[href="/words/list"] {
        padding: 2.4px 17px;
    }

    .Navbar a[href="/payment"] {
        padding: 2.4px 14px;
    }

    .Navbar a[href="/auth"] {
        padding: 2.4px 16px;
    }

    .Navbar a {
        flex-grow: 1;
    }
}