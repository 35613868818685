.Register #step2Div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.Register #step2Div>div{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}