/* Layout ---------------------------------------------- */
.LanguageSelectorForTranslation form {
    color: rgb(111, 255, 207);
    padding-top: 5px;
}

.LanguageSelectorForTranslation #settings_tooltip {
    background-color: rgb(111, 255, 207);
    padding: 8px;
}

/* h3, p ---------------------------------------------- */
.LanguageSelectorForTranslation h3 {
    color: rgb(111, 255, 207);
    margin: 20px 0 0;
}

.LanguageSelectorForTranslation p {
    margin: 0;
}

/* input ---------------------------------------------- */
.LanguageSelectorForTranslation select {
    background-color: #383838;
    color: rgb(111, 255, 207);
    border: 1px solid rgb(70, 169, 136);
    border-radius: 4px;
    font-family: inherit;
    cursor: pointer;
}

.LanguageSelectorForTranslation select {
    padding: 2px;
}

.LanguageSelectorForTranslation select:focus {
    outline: none;
}

/* table ---------------------------------------------- */
.LanguageSelectorForTranslation table {
    margin: auto;
}