/* Layout ---------------------------------------------- */
.LanguageSelector form {
    border: 1px solid transparent !important;
    background-color: #343434;
    color: #5ae1ff;
}

.LanguageSelector #translationDiv,
.LanguageSelector #SpeechDiv {
    background-color: rgb(18, 18, 18);
    border: 1px dashed #7a7a7a;
    border-radius: 10px;
    padding: 7px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LanguageSelector #translationDiv>div,
.LanguageSelector #SpeechDiv>div {
    padding: 3px;
    display: flex;
    flex-direction: column;
}

/* h ---------------------------------------------- */
.LanguageSelector h3 {
    color: whitesmoke;
}

.LanguageSelector h4 {
    margin: 5px;
    letter-spacing: 1px;
}

/* input ---------------------------------------------- */
.LanguageSelector select {
    background-color: #383838;
    color: #f2f2f2;
    border: 1px solid #5ae1ff;
    border-radius: 4px;
    font-family: inherit;
}

.LanguageSelector select {
    padding: 2px;
}

.LanguageSelector select:focus {
    outline: none;
}

.LanguageSelector #speechLanguage_select {
    width: 193px;
}

/* Label */
.LanguageSelector label {
    font-size: 15px;
    color: #5ae1ff;
    margin: 1px;
}


/* button ---------------------------------------------- */
/* Settings */
.LanguageSelector .fa-gear {
    color: #5ae1ff;
    font-size: 22px;
    cursor: pointer;
    padding: 6px;
}

.LanguageSelector #settings_tooltip,
.LanguageSelector #close_tooltip {
    color: black;
    background-color: #5ae1ff;
    padding: 5px 7px;
    font-weight: bold;
}

/* Close --- */
.LanguageSelector .fa-circle-xmark {
    cursor: pointer;
    color: #5ae1ff;
    font-size: 22px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0px;
}

/* Save --- */
.LanguageSelector .save {
    color: #5ae1ff !important;
    border-top: 2px solid #5ae1ff !important;
    border-bottom: 2px solid #5ae1ff !important;
    border-right: 1px solid #5ae1ff !important;
    border-left: 1px solid #5ae1ff !important;
    background-color: rgb(22, 22, 22);
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 15px;
    padding: 4px 9px;
    margin-top: 5px;
    margin-bottom: 7px;
}

@media (min-width: 426px) {
    .LanguageSelector .save:hover {
        color: black !important;
        background-color: #5ae1ff;
    }
}